import Vue from 'vue';
import Router from 'vue-router';
import Loan from "@/components/Loan";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '*',
            name: "Prestamo",
            component: Loan,
            meta: {
                title: "Gracias por su solicitud",
                icon:"/lock.png"
            },
        },
        {
            path: '/loan',
            name: "Prestamo",
            component: Loan,
            meta: {
                title: "Gracias por su solicitud",
                icon:"/lock.png"
            }
        }
    ],
})
