<template>
  <router-view></router-view>
</template>
<script type="text/javascript"  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script>
<script>
import Loan from './components/Loan.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Loan,
    Header
  }
}
</script>
<style>
@import './assets/style/bootstrap.css';
@import './assets/style/style.css';
</style>
