<template>
  <div class="container">
    <notifications group="app" position="top right" />
    <div class="loader" v-if="loading">
      <span class="helper"></span>
    </div>
    <div :disabled="loading">
      <Header/>
      <div class="mb-4 rounded-3 bg-hero" :disabled="loading">
        <div class="row justify-content-end">
          <div class="col-xs-12 col-md-6 col-lg-4">

            <!-- Formulario -->
            <div class="m-4 p-4 border rounded-3 form-config">
              <div class="form-group">
                <label for="exampleInputCI">Documento de Identidad</label>
                <input v-model="ci" class="form-control" id="exampleInputCI" placeholder="CI, sin puntos ni guiones"
                       type="text">
              </div>
              <br>
              <div class="form-group">
                <label for="exampleInputTel">Celular</label>
                <input v-model="phone" class="form-control" id="exampleInputTel" placeholder="09" type="tel">
              </div>
              <br>
              <div class="form-group">
                <label for="exampleInputDate">Fecha de Nacimiento</label>
                <input v-model="date" class="form-control" id="exampleInputDate" placeholder="16-10-1984" type="date" :max="minDate">
              </div>
              <br>
              <div class="form-check">
                <input v-model="isValid" class="form-check-input" id="exampleCheck1" type="checkbox">
                <label class="form-check-label text-secondary" for="exampleCheck1">Consiento que EFAMAR
                  SA informe a WENANCE
                  URUGUAY S.A. mis datos personales aqui proporcionados con el objetivo
                  de generar una solicitud de préstamo.<a class="text-decoration-none"
                                                          href="https://welp.com.uy/wp-content/uploads/2021/10/20200210_Terminos-y-condiciones.pdf">
                    Términos y Condiciones.</a></label>
              </div>
              <br>

              <div class="form-group">
                <div id="content-mobile" class="text-center">
                  <vue-recaptcha ref="recaptcha" :loadRecaptchaScript="true"
                                 @verify="onVerify" sitekey="6Lc-b98aAAAAAHe42YsYFoHNr4Gw57LvTLoys9Zk">
                  </vue-recaptcha>
                  <br>
                  <button class="btn btn-primary" :disabled="!isValidForm" @click="signInButtonPressed">Solicitar Efectivo
                  </button>
                </div>
              </div>

              <div class="form-group">
                <div id="content-desktop" class="text-left">
                  <vue-recaptcha style="transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;" size="500" ref="recaptcha" :loadRecaptchaScript="true"
                                 @verify="onVerify" sitekey="6Lc-b98aAAAAAHe42YsYFoHNr4Gw57LvTLoys9Zk">
                  </vue-recaptcha>
                  <br>
                  <button class="btn btn-primary" :disabled="!isValidForm"  @click="signInButtonPressed">Solicitar Efectivo
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <footer class="pb-3 mb-3 border-bottom pt-3 mt-3 border-top">
        <div class="row align-items-md-stretch" :disabled="loading">
          <div class="col-md-6 align-items-baseline">
            <a href="https://www.urupago.com.uy/"><img height="34px" src="../assets/up.png" width="164px"></a>

          </div>
          <div class="col-md-6 text-center">
            <h4></h4>
          </div>
        </div>

      </footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Vue from "vue";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Loan',
  components: {Header,VueRecaptcha},
  data() {
    return {
      ci: "",
      phone: "",
      date: "",
      isValid: false,
      robot: false,
      loading: false,
      disableForm: true
    };
  },
  computed: {
    isValidForm() {
      return /^[0-9]{9}$/.test(this.phone) && /^[0-9]{8}$/.test(this.ci) && this.date != "" && this.isValid && this.robot;
    },
    minDate() {
      var startdate = Vue.moment();
      startdate.subtract(18, 'y');
      return startdate.format("YYYY-MM-DD");
    },
  },
  methods: {
    onVerify: function (response) {
      if (response) this.robot = true;
    },
    resetWindow: function (){
      Object.assign(this.$data, this.$options.data.call(this));
    },
    signInButtonPressed() {
      this.loading = true;
      const post = {
        document: this.ci,
        cellphone: this.phone,
        birthday: this.date
      }
      Vue.axios.post(process.env.VUE_APP_API + "/loan-req/",post).then(() => {
        this.loading = false;
        Vue.notify({
          group: 'app',
          title: 'Información',
          text: "Solicitud enviada satisfactoriamente."
        })
        this.resetWindow();
      }).catch(err => {
        this.loading = false;
        Vue.notify({
          group: 'app',
          title: 'Error',
          type: 'error',
          text: "Problemas al realizar la solicitud"
        })
        console.log(err.response);
      });
    }
  }
}
</script>
