import Vue from 'vue'
import App from './App.vue'
import Notifications from 'vue-notification'
import 'jquery/src/jquery.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import router from './router/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueMoment, {
    moment,
})


Vue.use(VueGtag, {
  config: { id: "G-SWK9BE0R7Y" }
});
Vue.use(Notifications)
axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = "64bab634b6614dacb8787baa59f78e7f";
axios.interceptors.request.use(function (config){
  config.headers.common['Content-Type'] = 'application/json';
  return config;
},function (error){
  return Promise.reject(error);
})
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
