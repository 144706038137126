<template>
  <header class="pb-3 mb-3 border-bottom pt-3 mt-3 border-top bg-header text-center">
    <h3 class="bg-header-tittle"></h3>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>
